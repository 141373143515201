import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Footer from '../Footer';
import Header from '../Header';
import RequestContact from '../RequestContact';

import seguroImage from '../../assets/img/ng/particulares.jpeg';


import automovel1 from '../../assets/img/seguros/seguro-automovel.jpeg';
import automovel2 from '../../assets/img/seguros/seguro-automovel1.jpeg';
import automovel3 from '../../assets/img/seguros/seguro-automovel2.jpeg';
import seguroAP from '../../assets/img/seguros/seguro-ap.jpeg';
import motoImage from '../../assets/img/seguros/seguro-moto.jpg';
import seguroCacador from '../../assets/img/seguros/seguro-cacador.jpg';
import seguroAPDesp from '../../assets/img/seguros/acidentes-pessoais-desportivo.jpeg';
import seguroBarco from '../../assets/img/seguros/seguro-barco.jpeg';
import seguroSaude from '../../assets/img/seguros/seguro-saude.jpeg';
import rcJunior from '../../assets/img/seguros/rc-junior.jpeg';
import rcCaes from '../../assets/img/seguros/seguro-animais.jpg';
import vidaFamiliar from '../../assets/img/seguros/seguro-vida-familiar.jpeg';
import seguroViagem from '../../assets/img/seguros/seguro-viagem-barato-ferias.jpeg';
import seguroMGEN from '../../assets/img/seguros/saude-mgen.jpeg';

import {
  Link
} from "react-router-dom";


class SegurosParticulares extends Component {
  getToken() {
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken?.token
  }
  render() {
    const token = sessionStorage.getItem('token');
    return (
      <div className="content-wrapper">
        <Header />
          <section className="wrapper bg-gray">
      			<div className="container pt-10 pb-8 pt-md-14 pb-md-12 text-center">
      				<div className="row">
      					<div className="col-lg-8 mx-auto">
      						<h1 className="display-1 mb-3">Seguros Particulares</h1>
      						<nav className="d-inline-block" aria-label="breadcrumb">
      							<ol className="breadcrumb">
      								<li className="breadcrumb-item"><Link to="/">Loja Online</Link></li>
      								<li className="breadcrumb-item active">Seguros Particulares</li>
      							</ol>
      						</nav>
      					</div>
      				</div>
      			</div>
      		</section>
      		<section className="wrapper">
      			<div className="container py-14 pt-md-11 pt-11 pb-md-16">
                <div className="row text-center">
        					<div className="col-lg-9 col-xl-8 col-xxl-7 mx-auto">
        						<p className="mb-6">A Nacionalgest analisa as suas necessidades para lhe apresentar as melhores soluções do mercado, sendo que o nosso objetivo é que se sinta protegido em todos os momentos da sua vida. Somos parceiros de todos os seguradores do mercado e dispomos de uma vasta gama de seguros para a sua proteção pessoal, do seu património e das suas responsabilidades.</p>
        					</div>
        				</div>
                <div className="row grid-view gx-md-8 gx-xl-10 gy-8 gy-lg-0 mb-md-10 pt-7" style={{justifyContent:'center'}}>
        					<div className="col-md-4 col-lg-4">
        						<div className="position-relative">
        							<div className="card">
                        <figure className="card-img-top overlay overlay1 hover-scale"><a href="https://nacionalgest.parcerias.tranquilidade.pt/IOS_Auto/" target="_blank"> <img src={automovel2} alt="Seguro Automóvel" /></a>
                          <figcaption>
                            <h5 className="from-top mb-0">Simular</h5>
                          </figcaption>
                        </figure>
        								<div className="card-body px-6 py-5">
        									<h4 className="mb-1">Seguro Automóvel</h4>
        									<p className="mb-0">O Seguro Automóvel garante a segurança e assistência do condutor e dos ocupantes durante toda a viagem.</p>
        								</div>
                        <div id="simular-button" className="p-0 card-footer">
    											<a href="https://nacionalgest.parcerias.tranquilidade.pt/IOS_Auto/" target="_blank" className="btn btn-sm btn-primary rounded-0">Simular</a>
    										</div>
        							</div>
        						</div>
        					</div>
        					<div className="col-md-4 col-lg-4">
        						<div className="position-relative">
        							<div className="card">
                        <figure className="card-img-top overlay overlay1 hover-scale"><Link to="seguros-particulares/motos/simulador"> <img src={motoImage} alt="Seguro Moto" /></Link>
                          <figcaption>
                            <h5 className="from-top mb-0">Simular</h5>
                          </figcaption>
                        </figure>
        								<div className="card-body px-6 py-5">
        									<h4 className="mb-1">Seguro Moto</h4>
                            <p className="mb-0">Responsabilidade Civil, complementada com Assistência em Viagem e Proteção Jurídica, assim como os danos no veículo.</p>
        								</div>
                        <div id="simular-button" className="p-0 card-footer">
    											<Link to="seguros-particulares/motos/simulador" className="btn btn-sm btn-primary rounded-0">Simular</Link>
    										</div>
        							</div>
        						</div>
        					</div>
                  <div className="col-md-4 col-lg-4">
                    <div className="position-relative">
                      <div className="card">
                        <figure className="card-img-top overlay overlay1 hover-scale"><Link to="/seguros-particulares/viagem/simulador"> <img src={seguroViagem} alt="Seguro Viagem" /></Link>
                          <figcaption>
                            <h5 className="from-top mb-0">Simular</h5>
                          </figcaption>
                        </figure>
                        <div className="card-body px-6 py-5">
                          <h4 className="mb-1">Seguro de Viagem</h4>
                          <p className="mb-0"> Um <b><i>seguro de viagem barato</i></b>, abrangente e versátil que lhe permitirá ter uma viagem tranquila. Não arrisque!</p>
                        </div>
                        <div id="simular-button" className="p-0 card-footer">
                          <Link to="/seguros-particulares/viagem/simulador" className="btn btn-sm btn-primary rounded-0">Simular</Link>
                        </div>
                      </div>
                    </div>
                  </div>
        				</div>
                <div className="row grid-view gx-md-8 gx-xl-10 gy-8 gy-lg-0 mb-md-10 pt-7" style={{justifyContent:'center'}}>
                <div className="col-md-4 col-lg-4">
                  <div className="position-relative">
                    <div className="card">
                      <figure className="card-img-top overlay overlay1 hover-scale"><Link to="seguros-particulares/saude/simulador"> <img src={seguroSaude} alt="Seguro Saúde" /></Link>
                        <figcaption>
                          <h5 className="from-top mb-0">Simular</h5>
                        </figcaption>
                      </figure>
                      <div className="card-body px-6 py-5">
                        <h4 className="mb-1">Seguro de Saúde - Acesso à Rede</h4>
                          <p className="mb-0"> O Seguro de saúde que o protege a si e à sua família, em qualquer momento da  sua vida. Acesso à rede hospitalar desde 10€/mês.</p>
                      </div>
                      <div id="simular-button" className="p-0 card-footer">
                        <Link to="seguros-particulares/saude/simulador" className="btn btn-sm btn-primary rounded-0">Simular</Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4">
                  <div className="position-relative">
                    <div className="card">
                      <figure className="card-img-top overlay overlay1 hover-scale"><a href="https://my.mgen.pt/sim.aspx?user_key=D713D886-9447-40FE-BE0F-85609689B415&iframe=1&simplex=1&grupo_id=asds" target="_blank" rel="noreferrer"> <img src={seguroMGEN} alt="Seguro Saude" /></a>
                        <figcaption>
                          <h5 className="from-top mb-0">Simular</h5>
                        </figcaption>
                      </figure>
                      <div className="card-body px-6 py-5">
                        <h4 className="mb-1">Seguro de Saúde - Famílias</h4>
                        <p className="mb-0">O Seguro de saúde que o protege a si e à sua família, em qualquer momento da sua vida. Sem questionários e sem complicações.</p>
                      </div>
                      <div id="simular-button" className="p-0 card-footer">
                        <a href="https://my.mgen.pt/sim.aspx?user_key=D713D886-9447-40FE-BE0F-85609689B415&iframe=1&simplex=1&grupo_id=asds" target="_blank" rel="noreferrer" className="btn btn-sm btn-primary rounded-0">Simular</a>
                      </div>
                    </div>
                  </div>
                </div>
                  <div className="col-md-4 col-lg-4">
        						<div className="position-relative">
        							<div className="card">
                        <figure className="card-img-top overlay overlay1 hover-scale"><Link to="seguros-particulares/acidentes-pessoais/simulador"> <img src={seguroAPDesp} alt="Seguro Acidentes Pessoais" /></Link>
                          <figcaption>
                            <h5 className="from-top mb-0">Simular</h5>
                          </figcaption>
                        </figure>
        								<div className="card-body px-6 py-5">
        									<h4 className="mb-1">Acidentes Pessoais Desportivo</h4>
                            <p className="mb-0">O Seguros de Acidentes Pessoais Desportivo protege-o na prática de atividades desportivas.</p>
        								</div>
                        <div id="simular-button" className="p-0 card-footer">
    											<Link to="seguros-particulares/acidentes-pessoais/simulador" className="btn btn-sm btn-primary rounded-0">Simular</Link>
    										</div>
        							</div>
        						</div>
        					</div>
                </div>
                <div className="row grid-view gx-md-8 gx-xl-10 gy-8 gy-lg-0 mb-md-10 pt-7">
                <div className="col-md-4 col-lg-4">
                  <div className="position-relative">
                    <div className="card">
                      <figure className="card-img-top overlay overlay1 hover-scale"><Link to="seguros-particulares/animais/simulador"> <img src={rcCaes} alt="Seguro Acidentes Pessoais" /></Link>
                        <figcaption>
                          <h5 className="from-top mb-0">Simular</h5>
                        </figcaption>
                      </figure>
                      <div className="card-body px-6 py-5">
                        <h4 className="mb-1">Seguro Animais de Companhia</h4>
                          <p className="mb-0">Um seguro simples para quem apenas se quer proteger contra possíveis danos causados pelos seus animais de companhia.</p>
                      </div>
                      <div id="simular-button" className="p-0 card-footer">
                        <Link to="seguros-particulares/animais/simulador" className="btn btn-sm btn-primary rounded-0">Simular</Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4">
                  <div className="position-relative">
                    <div className="card">
                      <figure className="card-img-top overlay overlay1 hover-scale"><Link to="seguros-particulares/embarcacoes/simulador"> <img src={seguroBarco} alt="Seguro Embarcações" /></Link>
                        <figcaption>
                          <h5 className="from-top mb-0">Simular</h5>
                        </figcaption>
                      </figure>
                      <div className="card-body px-6 py-5">
                        <h4 className="mb-1">Seguro de Embarcações</h4>
                        <p className="mb-0">Aprecie ao máximo os seus momentos de lazer, no mar e em terra,  minimizando os imprevistos que possam acontecer.</p>
                      </div>
                      <div id="simular-button" className="p-0 card-footer">
                        <Link to="seguros-particulares/embarcacoes/simulador" className="btn btn-sm btn-primary rounded-0">Simular</Link>
                      </div>
                    </div>
                  </div>
                </div>
                  <div className="col-md-4 col-lg-4">
        						<div className="position-relative">
        							<div className="card">
                        <figure className="card-img-top overlay overlay1 hover-scale"><Link to="seguros-particulares/ap-junior/simulador"> <img src={rcJunior} alt="Seguro Acidentes Pessoais" /></Link>
                          <figcaption>
                            <h5 className="from-top mb-0">Simular</h5>
                          </figcaption>
                        </figure>
        								<div className="card-body px-6 py-5">
        									<h4 className="mb-1">Acidentes Pessoais Júnior</h4>
                            <p className="mb-0">O Seguro de Acidentes Pessoais para crianças e adolescentes. Protege a criança e também os acidentes causados a terceiros.</p>
        								</div>
                        <div id="simular-button" className="p-0 card-footer">
    											<Link to="seguros-particulares/ap-junior/simulador" className="btn btn-sm btn-primary rounded-0">Simular</Link>
    										</div>
        							</div>
        						</div>
        					</div>
                </div>
                <div className="row grid-view gx-md-8 gx-xl-10 gy-8 gy-lg-0 mb-md-10 pt-7">
                <div className="col-md-4 col-lg-4">
                  <div className="position-relative">
                    <div className="card">
                      <figure className="card-img-top overlay overlay1 hover-scale"><Link to="seguros-particulares/rc-cacador/simulador"> <img src={seguroCacador} alt="Seguro Acidentes Pessoais" /></Link>
                        <figcaption>
                          <h5 className="from-top mb-0">Simular</h5>
                        </figcaption>
                      </figure>
                      <div className="card-body px-6 py-5">
                        <h4 className="mb-1">Responsabilidade Civil Caçador</h4>
                        <p className="mb-0">As melhores soluções de proteção, com garantias que efetivamente lhe interessam na prática do seu desporto favorito.</p>
                      </div>
                      <div id="simular-button" className="p-0 card-footer">
                        <Link to="seguros-particulares/rc-cacador/simulador" className="btn btn-sm btn-primary rounded-0">Simular</Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4">
                  <div className="position-relative">
                    <div className="card">
                      <figure className="card-img-top overlay overlay1 hover-scale"><a href="https://seguros.metlife.pt/partners/nacionalgest" target="_blank" rel="noreferrer"> <img src={vidaFamiliar} alt="Seguro Vida" /></a>
                        <figcaption>
                          <h5 className="from-top mb-0">Simular</h5>
                        </figcaption>
                      </figure>
                      <div className="card-body px-6 py-5">
                        <h4 className="mb-1">Seguro de Vida</h4>
                        <p className="mb-0">Garanta a protecção da sua família ou o pagamento da sua hipoteca ao Banco, uma solução que se adapta às suas necessidades.</p>
                      </div>
                      <div id="simular-button" className="p-0 card-footer">
                        <a href="https://seguros.metlife.pt/partners/nacionalgest" target="_blank" rel="noreferrer" className="btn btn-sm btn-primary rounded-0">Simular</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
    		     </div>
           </section>
          {/*<section className="wrapper bg-light wrapper-border">
            <div className="container py-14 py-md-16">
              <h2 className="fs-15 text-uppercase text-muted text-center mb-8">Companhias com quem trabalhamos</h2>
              <div className="carousel owl-carousel clients" data-margin="30" data-loop="true" data-dots="false" data-autoplay="true" data-autoplay-timeout="3000" data-responsive='{"0":{"items": "2"}, "768":{"items": "4"}, "992":{"items": "5"}, "1200":{"items": "6"}, "1400":{"items": "7"}}'>
                <div className="item px-5"><img src={companhia1} alt="" /></div>
                <div className="item px-5"><img src={companhia2} alt="" /></div>
                <div className="item px-5"><img src={companhia3} alt="" /></div>
                <div className="item px-5"><img src={companhia4} alt="" /></div>
                <div className="item px-5"><img src={companhia5} alt="" /></div>
                <div className="item px-5"><img src={companhia6} alt="" /></div>
                <div className="item px-5"><img src={companhia7} alt="" /></div>
                <div className="item px-5"><img src={companhia8} alt="" /></div>
              </div>
            </div>
          </section>
          */}
        <RequestContact />
      <Footer />
    </div>
    )
  }
}
export default SegurosParticulares;
