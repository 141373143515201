import React from 'react';
import ReactDOM from 'react-dom';
import Simulador from './components/Simulador';
import SimuladorAP from './components/particulares/acidentesp/SimuladorAP';
import SimuladorAPParceiros from './components/parceiros/particulares/acidentesp/SimuladorAP';
import SimuladorAPJuniorParceiros from './components/parceiros/particulares/acidentesp/SimuladorAPJunior';
import ParceirosSuccessAP from './components/parceiros/particulares/acidentesp/ParceirosSuccessAP';
import ParceirosSuccessAPJunior from './components/parceiros/particulares/acidentesp/ParceirosSuccessAPJunior';
import SimuladorAPJunior from './components/particulares/acidentesp/SimuladorAPJunior';
import SimuladorRCCacador from './components/particulares/RC_cacador/SimuladorRCCacador';
import SimuladorBarco from './components/particulares/embarcacoes/SimuladorBarco';
import SimuladorSaude from './components/particulares/saude/SimuladorSaude';
import SimuladorAnimais from './components/particulares/animais/SimuladorAnimais';
import SimuladorPredictable from './components/particulares/predictable/SimuladorPredictable';
import SimulacaoPredictable from './components/particulares/predictable/SimulacaoPredictable';
import ThanksPredictable from './components/particulares/predictable/ThanksPredictable';
import FailPredictable from './components/particulares/predictable/FailPredictable';
import SimuladorRCSurf from './components/empresas/surf/rc/SimuladorRCSurf';
import SimuladorAPSurf from './components/empresas/surf/ap/SimuladorAPSurf';
import SimuladorRCAgenciaViagens from './components/empresas/rc/agencias_viagem/SimuladorRCAgenciaViagens';
import SimuladorRCImobiliarias from './components/empresas/rc/imobiliarias/SimuladorRCImobiliarias';
import SimuladorRCCredito from './components/empresas/rc/credito/SimuladorRCCredito';
import SimuladorAICCOPN from './components/empresas/aiccopn/SimuladorAICCOPN';
import Base from './components/base/Base';
import RedirectToExternal from './components/RedirectToExternal';
import AgradecimentoNPS from './components/base/AgradecimentoNPS';
import AgradecimentoSF from './components/base/AgradecimentoSF';
import AgradecimentoDekuple from './components/base/AgradecimentoDekuple';
import Thanks from './components/Thanks';
import ThanksAP from './components/particulares/acidentesp/ThanksAP';
import ThanksAPJunior from './components/particulares/acidentesp/ThanksAPJunior';
import ThanksRCCacador from './components/particulares/RC_cacador/ThanksRCCacador';
import ThanksAnimais from './components/particulares/animais/ThanksAnimais';
import ThanksBarco from './components/particulares/embarcacoes/ThanksBarco';
import ThanksSaude from './components/particulares/saude/ThanksSaude';
import ThanksRCSurf from './components/empresas/surf/rc/ThanksRCSurf';
import ThanksAPSurf from './components/empresas/surf/ap/ThanksAPSurf';
import ThanksRCAgenciaViagens from './components/empresas/rc/agencias_viagem/ThanksRCAgenciaViagens';
import ThanksViagem from './components/empresas/viagem/ThanksViagem';
import ThanksRCCredito from './components/empresas/rc/credito/ThanksRCCredito';
import ThanksRCImobiliarias from './components/empresas/rc/imobiliarias/ThanksRCImobiliarias';
import ThanksAICCOPN from './components/empresas/aiccopn/ThanksAICCOPN';
import Parceiros from './components/parceiros/Parceiros';
import SimuladoresParceiros from './components/parceiros/SimuladoresParceiros';
import NotificarClientesParceiros from './components/parceiros/NotificarClientesParceiros';
import SegurosParticularesParceiros from './components/parceiros/particulares/SegurosParticularesParceiros';
import SegurosEmpresasParceiros from './components/parceiros/empresas/SegurosEmpresasParceiros';
import ParceirosEmpresas from './components/parceiros/empresas/ParceirosEmpresas';
import ParceirosAnimais from './components/parceiros/particulares/animais/ParceirosAnimais';
import ParceirosCacador from './components/parceiros/particulares/RC_cacador/ParceirosCacador';
import ParceirosBarco from './components/parceiros/particulares/embarcacoes/ParceirosBarco';
import ParceirosSaude from './components/parceiros/particulares/saude/ParceirosSaude';
import ParceirosRCSurf from './components/parceiros/empresas/surf/rc/ParceirosRCSurf';
import ParceirosAPSurf from './components/parceiros/empresas/surf/ap/ParceirosAPSurf';
import ParceirosRCAgenciaViagens from './components/parceiros/empresas/rc/agencias_viagens/ParceirosRCAgenciaViagens';
import ParceirosCacadorSucesso from './components/parceiros/particulares/RC_cacador/ParceirosCacadorSucesso';
import ParceirosBarcoSucesso from './components/parceiros/particulares/embarcacoes/ParceirosBarcoSucesso';
import ParceirosSaudeSucesso from './components/parceiros/particulares/saude/ParceirosSaudeSucesso';
import ParceirosAPSurfSucesso from './components/parceiros/empresas/surf/ap/ParceirosAPSurfSucesso';
import ParceirosRCSurfSucesso from './components/parceiros/empresas/surf/rc/ParceirosRCSurfSucesso';
import ParceirosRCAgenciaViagensSucesso from './components/parceiros/empresas/rc/agencias_viagens/ParceirosRCAgenciaViagensSucesso';
import ParceirosAnimaisSucesso from './components/parceiros/particulares/animais/ParceirosAnimaisSucesso';
import ParceirosRCImobiliarias from './components/parceiros/empresas/rc/imobiliarias/ParceirosRCImobiliarias';
import ParceirosRCImobiliariasSucesso from './components/parceiros/empresas/rc/imobiliarias/ParceirosRCImobiliariasSucesso';
import ParceirosRCCredito from './components/parceiros/empresas/rc/credito/ParceirosRCCredito';
import ParceirosRCCreditoSucesso from './components/parceiros/empresas/rc/credito/ParceirosRCCreditoSucesso';
import ParceirosSimuladorPredictable from './components/parceiros/particulares/predictable/ParceirosSimuladorPredictable';
import ParceirosSimulacaoPredictable from './components/parceiros/particulares/predictable/ParceirosSimulacaoPredictable';
import ParceirosThanksPredictable from './components/parceiros/particulares/predictable/ParceirosThanksPredictable';
import ParceirosFailPredictable from './components/parceiros/particulares/predictable/ParceirosFailPredictable';
import SuccessCertificadosParceiros from './components/parceiros/SuccessCertificadosParceiros';
import SimulatorCertificadosParceiros from './components/parceiros/SimulatorCertificadosParceiros';
import SegurosParticulares from './components/particulares/SegurosParticulares';
import SegurosEmpresas from './components/empresas/SegurosEmpresas';
import ScrollToTop from './components/utils/ScrollToTop';
import InscricaoEvento from './components/eventos/InscricaoEvento';
import InscricaoJantarNatal from './components/eventos/InscricaoJantarNatal';
import InscricaoConvencao from './components/eventos/InscricaoConvencao';
import InscricaoTeambuilding from './components/eventos/InscricaoTeambuilding';
import InscricaoTeambuilding2 from './components/eventos/InscricaoTeambuilding2';
import InscricaoTeambuildingStep2 from './components/eventos/InscricaoTeambuildingStep2';
import InscricaoTeambuildingCompanhias from './components/eventos/InscricaoTeambuildingCompanhias';
import InscricaoTeambuildingAgentes from './components/eventos/InscricaoTeambuildingAgentes';
import InscricaoConvencaoCompanhias from './components/eventos/InscricaoConvencaoCompanhias';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";


function App() {

  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={Base} />
        <Route exact path="/tacadoribatejo" render={() => <RedirectToExternal url="https://cloud.mkt.nacionalgest.pt/taca-ribatejo" />} />
        <Route exact path={`/protocolos/aiccopn`} component={SimuladorAICCOPN} />
        <Route exact path={`/protocolos/aiccopn/obrigado`} component={ThanksAICCOPN} />
          <Route
            path="/seguros-empresas"
            render={({ match: { url } }) => (
              <>
                <Route exact path={`${url}/`} component={SegurosEmpresas} />
                <Route exact path={`${url}/surf/rc/simulador`} component={SimuladorRCSurf} />
                <Route exact path={`${url}/surf/rc/obrigado`} component={ThanksRCSurf} />
                <Route exact path={`${url}/surf/ap/simulador`} component={SimuladorAPSurf} />
                <Route exact path={`${url}/surf/ap/obrigado`} component={ThanksAPSurf} />
                <Route exact path={`${url}/rc/agencia-viagens/simulador`} component={SimuladorRCAgenciaViagens} />
                <Route exact path={`${url}/rc/agencia-viagens/obrigado`} component={ThanksRCAgenciaViagens} />
                <Route exact path={`${url}/rc/imobiliarias/simulador`} component={SimuladorRCImobiliarias} />
                <Route exact path={`${url}/rc/imobiliarias/obrigado`} component={ThanksRCImobiliarias} />
                <Route exact path={`${url}/rc/intermediarios-credito/simulador`} component={SimuladorRCCredito} />
                <Route exact path={`${url}/rc/intermediarios-credito/obrigado`} component={ThanksRCCredito} />
              </>
          )}
          />
        <Route exact path={`/nps/obrigado`} component={AgradecimentoNPS} />
        <Route exact path={`/SF/obrigado`} component={AgradecimentoSF} />
        <Route exact path={`/experiencias/obrigado`} component={AgradecimentoDekuple} />
        // <Route exact path={`/eventos/inscricao`} component={InscricaoEvento} />
        <Route exact path={`/eventos/jantar-natal/inscricao`} component={InscricaoJantarNatal} />
        <Route exact path={`/eventos/convencao/inscricao`} component={InscricaoConvencao} />
        <Route exact path={`/eventos/convencao/inscricao-`} component={InscricaoConvencaoCompanhias} />
        <Route exact path={`/eventos/teambuilding/inscricao`} component={InscricaoTeambuilding2} />
        <Route exact path={`/eventos/teambuilding/inscricao-add`} component={InscricaoTeambuildingStep2} />
        <Route exact path={`/eventos/teambuilding/inscricao-ext`} component={InscricaoTeambuildingCompanhias} />
        <Route exact path={`/eventos/teambuilding/inscricao-agentes`} component={InscricaoTeambuildingAgentes} />
        <Route
          path="/seguros-particulares"
          render={({ match: { url } }) => (
            <>
              <Route exact path={`${url}/`} component={SegurosParticulares} />
              <Route exact path={`${url}/motos/simulador`} component={Simulador} />
              <Route exact path={`${url}/motos/obrigado`} component={Thanks} />
              <Route exact path={`${url}/acidentes-pessoais/simulador`} component={SimuladorAP} />
              <Route exact path={`${url}/ap-junior/simulador`} component={SimuladorAPJunior} />
              <Route exact path={`${url}/rc-cacador/simulador`} component={SimuladorRCCacador} />
              <Route exact path={`${url}/embarcacoes/simulador`} component={SimuladorBarco} />
              <Route exact path={`${url}/saude/simulador`} component={SimuladorSaude} />
              <Route exact path={`${url}/animais/simulador`} component={SimuladorAnimais} />
              <Route exact path={`${url}/viagem/simulador`} component={SimuladorPredictable} />
              <Route exact path={`${url}/viagem/simulacao`} component={SimulacaoPredictable} />
              <Route exact path={`${url}/viagem/obrigado`} component={ThanksPredictable} />
              <Route exact path={`${url}/viagem/fail`} component={FailPredictable} />
              <Route exact path={`${url}/acidentes-pessoais/obrigado`} component={ThanksAP} />
              <Route exact path={`${url}/ap-junior/obrigado`} component={ThanksAPJunior} />
              <Route exact path={`${url}/embarcacoes/obrigado`} component={ThanksBarco} />
              <Route exact path={`${url}/rc-cacador/obrigado`} component={ThanksRCCacador} />
              <Route exact path={`${url}/animais/obrigado`} component={ThanksAnimais} />
              <Route exact path={`${url}/saude/obrigado`} component={ThanksSaude} />
              </>
          )}
          />



          <Route
            path="/parceiros"
            render={({ match: { url } }) => (
              <>
                <Route path={`${url}/`} component={Parceiros} exact />
                <Route path={`${url}/simuladores`} component={SimuladoresParceiros} exact />
                <Route path={`${url}/notificacoes`} component={NotificarClientesParceiros} exact />

                <Route path={`${url}/particulares`}
                render={({ match: { url } }) => (
                  <>
                  <Route path={`${url}/`} component={SegurosParticularesParceiros} exact />
                  <Route path={`${url}/acidentes-pessoais`} component={SimuladorAPParceiros} exact />
                  <Route path={`${url}/acidentes-pessoais/sucesso`} component={ParceirosSuccessAP} exact />
                  <Route path={`${url}/ap-junior`} component={SimuladorAPJuniorParceiros} exact />
                  <Route path={`${url}/ap-junior/sucesso`} component={ParceirosSuccessAPJunior} exact />
                  <Route path={`${url}/rc-cacador`} component={ParceirosCacador} exact />
                  <Route path={`${url}/rc-cacador/sucesso`} component={ParceirosCacadorSucesso} exact />
                  <Route path={`${url}/embarcacoes/`} component={ParceirosBarco} exact />
                  <Route path={`${url}/embarcacoes/sucesso`} component={ParceirosBarcoSucesso} exact />
                  <Route exact path={`${url}/viagem/simulador`} component={ParceirosSimuladorPredictable} />
                  <Route exact path={`${url}/viagem/simulacao`} component={ParceirosSimulacaoPredictable} />
                  <Route exact path={`${url}/viagem/obrigado`} component={ParceirosThanksPredictable} />
                  <Route exact path={`${url}/viagem/fail`} component={ParceirosFailPredictable} />
                  <Route path={`${url}/saude`} component={ParceirosSaude} exact />
                  <Route path={`${url}/saude/sucesso`} component={ParceirosSaudeSucesso} exact />
                  <Route path={`${url}/animais`} component={ParceirosAnimais} exact />
                  <Route path={`${url}/animais/sucesso`} component={ParceirosAnimaisSucesso} exact />
                  <Route path={`${url}/motos`} component={SimulatorCertificadosParceiros} exact />
                  <Route path={`${url}/motos/sucesso`} component={SuccessCertificadosParceiros} exact />
                  </>
                )}
                />
              <Route path={`${url}/empresas`}
                render={({ match: { url } }) => (
                  <>
                  <Route exact path={`${url}/`} component={SegurosEmpresasParceiros} />
                  <Route exact path={`${url}/surf/ap`} component={ParceirosAPSurf} />
                  <Route exact path={`${url}/surf/ap/sucesso`} component={ParceirosAPSurfSucesso} />
                  <Route exact path={`${url}/surf/rc`} component={ParceirosRCSurf} />
                  <Route exact path={`${url}/surf/rc/sucesso`} component={ParceirosRCSurfSucesso} />
                  <Route exact path={`${url}/rc/agencia-viagens`} component={ParceirosRCAgenciaViagens} />
                  <Route exact path={`${url}/rc/agencia-viagens/sucesso`} component={ParceirosRCAgenciaViagensSucesso} />
                  <Route exact path={`${url}/rc/imobiliarias`} component={ParceirosRCImobiliarias} />
                  <Route exact path={`${url}/rc/imobiliarias/sucesso`} component={ParceirosRCImobiliariasSucesso} />
                  <Route exact path={`${url}/rc/intermediarios-credito`} component={ParceirosRCCredito} />
                  <Route exact path={`${url}/rc/intermediarios-credito/sucesso`} component={ParceirosRCCreditoSucesso} />
                  </>
                )}
                />
              </>
            )}
          />
        </Switch>
      </Router>
  )
}

ReactDOM.render(
  <App />,
  document.querySelector('#root')
)
